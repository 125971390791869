import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "shop_mall",
  //   component: () => import("../views/ShopMall/index.vue"),
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/myOrder",
    name: "myOrder",
    component: () => import("../views/myOrder/index.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/logistics",
    name: "logistics",
    component: () => import("../views/myOrder/logistics.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/receiveAddress",
    name: "receiveAddress",
    component: () => import("../views/myOrder/receiveAddress.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/editAddress",
    name: "editAddress",
    component: () => import("../views/myOrder/editAddress.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/mall",
    name: "mall",
    component: () => import("../views/mall/mall.vue"),
  },
  {
    path: "/mallOption",
    name: "mallOption",
    component: () => import("../views/mall/mallOption.vue"),
  },
  {
    path: "/shoppingList",
    name: "shoppingList",
    component: () => import("../views/mall/shoppingList.vue"),
  },
  {
    path: "/formulaShop",
    name: "formulaShop",
    component: () => import("../views/mall/formulaShop.vue"),
  },
  {
    path: "/productionDetails",
    name: "productionDetails",
    component: () => import("../views/mall/productionDetails.vue"),
  },
  {
    path: "/confirmOrder",
    name: "confirmOrder",
    component: () => import("../views/mall/confirmOrder.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import("../views/pay/index.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("../views/feedback.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../views/aboutUs.vue"),
  },
  {
    path: "/text",
    name: "text",
    component: () => import("../views/text.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () => import("../views/download.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem("Authorization", to.query.token);
  }
  // console.log(getQueryString("token"));
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    if (
      !sessionStorage.getItem("Authorization") &&
      !localStorage.getItem("Authorization")
    ) {
      // console.log("in");
      // next({
      //   path: "/login",
      // });
      window.android.startLoginActivity();
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
